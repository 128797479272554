<template lang="">
  <div class="row">
    <div class="col-12">
      <h6 class="heading-small text-muted mb-4">จัดการปัญหา</h6>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">รหัสการสั่งซื้อ</label>
          <div class="col-sm-8">
            <base-input name="order_id" placeholder="รหัสการสั่งซื้อ" v-model="item.order_code" disabled></base-input>
          </div>
        </div>
        <div class="col-12 mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">ผู้แจ้งปัญหา</label>
          <div class="col-sm-8">
            <select class="form-control" :rules="{ required: true }" v-model="item.user_issuer" >
              <option v-for="issue in user_issuers" :key="`cbx_issuers_${issue.id}`"  :value="issue.id" >{{ issue.fullname }}</option>
            </select>
          </div>
        </div>
        <div class="col-12 mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">ประเภทปัญหา</label>
          <div class="col-sm-8">
            <select class="form-control" :rules="{ required: true }" v-model="item.issue_type_id">
              <option v-for="issue in issue_types" :key="`cbx_issues_${issue.id}`" :value="issue.id" >
                {{ issue.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">รายละเอียด</label>
          <div class="col-sm-8">
            <b-form-textarea name="topic" placeholder="รายละเอียด" v-model="item.topic" rows="3" max-rows="5" :rules="{ required: true }"></b-form-textarea>
          </div>
        </div>
        <div class="row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">ความสำคัญ</label>
          <div class="col-sm-8">
            <b-form-radio-group v-model="item.priority" :options="cbbOptions.priority" class="mt-2" name="priority" text-field="text"></b-form-radio-group>
            <base-input name="priority" v-model="item.priority" :rules="{ required: true }" class="custom-input-valid-datepicker"></base-input>
          </div>
        </div>
        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">สาเหตุปัญหา</label>
          <div class="col-sm-8">
            <select class="form-control" :rules="{ required: true }" v-model="item.cause_id">
              <option v-for="cause in cbbOptions.cause" :key="`cbx_cause_${cause.id}`" :value="cause.id" >{{ cause.text }}</option>
            </select>
          </div>
        </div>
        <div class="mb-4 row col-12" v-if="[1,5,6,8].includes(item.cause_id)">
          <label class="col-sm-4 col-form-label label_algin_right">Supplier</label>
          <div class="col-sm-8">
            <select class="form-control" v-model="item.cause_supplier_id">
              <option v-for="option in cbbOptions.supplier" :key="`cbx_supplier_${option.id}`" :value="option.id" >{{ option.text }}</option>
            </select>
            <!--
            <Select2 v-model="item.cause_supplier_id" :options="cbbOptions.supplier" :settings="{allowClear: true, multiple: false}" placeholder="Supplier"/>
            -->
          </div>
        </div>
        <div class="mb-4 row col-12" v-if="[2,6,7,8].includes(item.cause_id)">
          <label class="col-sm-4 col-form-label label_algin_right">Customer</label>
          <div class="col-sm-8">
            <select class="form-control"  v-model="item.cause_customer_id">
              <option v-for="option in cbbOptions.customer" :key="`cbx_customer_${option.id}`" :value="option.id" >{{ option.text }}</option>
            </select>
            <!--
            <Select2Customer :value="item.customer_name" :store_id="item.store_id" @change="getCustomerById" ref="Select2Customer"></Select2Customer>
            -->
          </div>
        </div>
        <div class="mb-4 row col-12" v-if="[3,4,5,7,8].includes(item.cause_id)">
          <label class="col-sm-4 col-form-label label_algin_right">User</label>
          <div class="col-sm-8">
            <select class="form-control" v-model="item.cause_user_id">
              <option v-for="option in cbbOptions.user" :key="`cbx_user_${option.id}`" :value="option.id" >{{ option.text }}</option>
            </select>
            <!--
            <Select2 v-model="item.cause_user_id" :options="cbbOptions.user" :settings="{allowClear: true, multiple: false}" placeholder="User"/>
          -->
          </div>
        </div>
        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">ส่วนลดให้ลูกค้า</label>
          <div class="col-sm-8">
            <b-form-input v-model="item.comp_customer_discount" placeholder="จำนวนหรือเปอร์เซ็นต์"></b-form-input>
          </div>
        </div>
        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">เพิ่ม/ลดเงินซัพ</label>
          <div class="col-sm-8">
            <b-form-input v-model="item.comp_supplier_discount" placeholder="จำนวนหรือเปอร์เซ็นต์"></b-form-input>
          </div>
        </div>
        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">ความเสียหายอื่น</label>
          <div class="col-sm-8">
            <b-form-input v-model="item.comp_other" placeholder="จำนวนหรือเปอร์เซ็นต์"></b-form-input>
          </div>
        </div>
        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">ยอดรวม</label>
          <div class="col-sm-8">
            <b-form-input v-model="item.comp_total" placeholder="จำนวนหรือเปอร์เซ็นต์"></b-form-input>
          </div>
        </div>

        <div class="mb-4 row col-12">
          <label class="col-sm-4 col-form-label label_algin_right">หมายเหตุ</label>
          <div class="col-sm-8">
            <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
        <hr class="my-4" />
        
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-md-12 ">
          <div class="mb-4">
            <label class="col-form-label">การแก้ไขปัญหา</label>
            <div class="">
              <b-form-textarea name="remark" placeholder="การแก้ไขปัญหา" v-model="item.solution" rows="3" max-rows="5" ></b-form-textarea>
              <base-input name="solution" v-model="item.solution" class="custom-input-valid-datepicker"></base-input>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-4">
                <label class="col-form-label">รูปภาพ</label>
                <div class="card" style="border: 1px solid #cad1d7; border-radius: 5px">
                  <div class="card-body m-auto" style="height: 230px; overflow: auto">
                    <input type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler" multiple name="imgs[]"/>
                    <span class="thumbnail" v-for="(img, index) in imgs.display" :key="`display_image_${index}`" >
                      <div class="action-delete">
                        <a href="javascript:void(0)" @click="deleteImageHandler(img, index)" > <i class="fa fa-times"></i> </a>
                      </div>
                      <img :src="img ? `${img.url || img}` : noImageData" class="img-thumbnail" style="max-width: 180px" @click="uploadFileHandler()"/>
                    </span>
                    <img v-if="!imgs.display.length" :src="noImageData" class style="max-width: 180px" @click="uploadFileHandler()"/>
                  </div>
                </div>
                <div class="invalid-feedback" style="display: block" v-if="!imgs.display.length">
                  The priority field is required
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Constants from "../../../util/constants";
export default {
  name: "product-form-product",
  data() {
    return {
      user : {},
      cbbOptions : {
        cause : Constants.issue_causes,
        priority : Constants.issue_priority,
        users : [],
        customer : [],
        supplier : [],
        type : [],
      },
      causeItems: Constants.issue_causes,
      noImageData: Constants.noImageData,
      options: Constants.issue_priority,
      issue_types: [],
      comp_customer_discount : "",
      comp_supplier_discount : "",
      comp_other : "",
      comp_total : "",
      user_issuer: null,
      /*
      item: {
        store_id : null,
        source : 1,
        issue_type_id : null,
        order_id : null,
        payment_id : null,
        delivery_id : null,
        topic : '',
        remark : '',
        user_issuer : null,
        priority: 0,
        cause_id : 0,
        cause_user_id : null,
        cause_supplier_id : null,
        cause_customer_id : null,
        cause_customer_name : null,
        comp_customer_discount : "",
        comp_supplier_discount : "",
        comp_other : "",
        comp_total : "",
        imgs: []
      },
      */
      user_issuers: [],
      /*
      imgs : {
        display : [],
        path : [],
        delete : []
      },
      */

      issue_types: [],
      user_issuers: [],
      options: Constants.issue_priority,
      noImageData: Constants.noImageData,
      vm: this,
    };
  },
  props: ["item", "images", "imgs"],
  methods: {
    async getCustomerById(customer_id) {
      this.item.cause_customer_id = customer_id;
    },
    onOutsideSubmit() {
      this.$refs.submitButton.click();
    },
    async saveHandler() {
      const file = this.imgs.path;
      let medias = [];
      this.imgs.path = [];

      if (file && file.length > 0) {
        const s3Auth = await this.HttpServices.authenS3();
        if (s3Auth._info) {
          for (const f of file) {
            const media = await this.HttpServices.easyUploadS3(s3Auth._info.secret.accessToken,f,"order-item-detail");
            medias.push(media);
          }
        }
      }

      this.item.imgs = JSON.stringify(medias);
      
      let params = JSON.parse(JSON.stringify(this.item));
      const result = await this.HttpServices.postData(`/ab_order/createIssue`,params);
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "แจ้งปัญหาสำเร็จ");
        this.$bvModal.hide("dialog_issue");
        this.$emit("completed", true);
        this.item = {};
        
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close() {
      this.$bvModal.hide("dialog_issue");
      this.item = {};
    },
    async getIssueType() {
      this.issue_types = await this.HttpServices.getMasterData(`/master/getIssueTypes`);
      if (this.issue_types && this.issue_types.length > 0) { this.item.issue_type_id = this.issue_types[0].id;}
      //this.item.images = [];
    },
    async getUser() {
      let params = `page=${1}&limit=${1000000}&ascDesc=asc`;
      const resultUsers = await this.HttpServices.getData(
        `/ab_users?${params}`
      );
      if (resultUsers.status.code == 200 && resultUsers.data.data.length > 0) {
        // this.item.issue_type_id = this.issue_types[0].id;
        this.user_issuers = resultUsers.data.data;
      }
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    async initIssue(source,source_id){
      this.item = {
        /*
        source : source,
        issue_type_id : null,
        order_id : source==1?source_id:null,
        payment_id : source==2?source_id:null,
        delivery_id : source==3?source_id:null,
        topic : '',
        remark : '',
        user_issuer : null,
        priority: 0,
        cause_id : 0,
        cause_user_id : null,
        cause_supplier_id : null,
        cause_customer_id : null,
        comp_customer_discount : "",
        comp_supplier_discount : "",
        comp_other : "",
        comp_total : "",
        imgs: [],
*/
        approved  : null,
        cause_customer_id : null,
        cause_id : null,
        cause_supplier_id : null,
        cause_user_id : null,
        comp_customer_discount : "",
        comp_other : "",
        comp_supplier_discount : "",
        comp_total : "",
        //created : null,
        //deleted : null,
        delivery_id: null,
        id: null,
        imgs: [],
        issue_type_id: null,
        issued: null,
        order_code: null,
        order_id: null,
        payment_id: null,
        priority: null,
        remark: null,
        solution: null,
        solved: null,
        source: null,
        status: null,
        topic: null,
        updated: null,
        user_approver: null,
        user_issuer: null,
        user_solver: null
      }
      //this.imgs = {display : [],path : [],delete : []}

      this.getIssueType();
      this.getUser();
      this.initData();

      this.SessionStorageUtils.decodeUser().then(user => {this.item.user_issuer = user.id;});

      switch(source){
        case 1:
          const order = await this.HttpServices.getData(`/ab_order/${source_id}`);
 
          this.item.cause_customer_id = order.data.customer_id;
          this.item.cause_customer_name = order.data.customer_name;
          this.item.cause_user_id = order.data.user_id;
          this.item.store_id = order.data.store_id;
          const delivery = await this.HttpServices.getData(`/ab_delivery/getDelvieryByOrderID/${source_id}`);
          if(delivery.data)this.item.cause_supplier_id = delivery.data.id;
          
          //this.$refs.Select2Customer.initialValue(order.data.customer_id,order.data.customer_name);
          break;
        case 2:
          const payment = await this.HttpServices.getData(`/ab_payment/${source_id}`);
          this.item.cause_customer_id = payment.data.item.customer_id;
          this.item.cause_user_id = payment.data.order.user_id;
          this.item.order_id = payment.data.order.id;
          this.item.store_id = payment.data.order.store_id;
          break;
        case 3:
          const dl = await this.HttpServices.getData(`/ab_delivery/${source_id}`);
          this.item.cause_supplier_id = dl.data.supplier_id;
          this.item.order_id = dl.data.order_id;
          break;
      }

    },
    async initData() {
      this.cbbOptions.user = await this.HttpServices.getMasterData("/master/getUsers");
      this.cbbOptions.supplier = await this.HttpServices.getMasterData("/master/getSuppliers");
      this.cbbOptions.customer = await this.HttpServices.getMasterData("/master/getCustomers");
    },



    async getIssueType() {
      this.issue_types = await this.HttpServices.getMasterData(
        `/master/getIssueTypes`
      );
    },
    async getUser() {
      let params = `page=${1}&limit=${1000000}&ascDesc=asc`;
      const resultUsers = await this.HttpServices.getData(
        `/ab_users?${params}`
      );
      if (resultUsers.status.code == 200 && resultUsers.data.data.length > 0) {
        // this.item.issue_type_id = this.issue_types[0].id;
        this.user_issuers = resultUsers.data.data;
      }
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {
            vm.imgs.display.push(event.target.result);
          };
          reader.readAsDataURL(img[i]);
          vm.imgs.path.push(img[i]);

          //vm.imgs.display.push(img[i]);
        }
        //vm.images = img;
      } else {
        /*
        this.displayImg = null;
        this.file = null;
        this.images = [];
        this.displayImgs = [];
        */
      }
    },
    deleteImageHandler(img, index) {
      const image = this.imgs.display[index];
      if (image.id != 0) {
        this.imgs.delete.push(image.id);
        if (image.id_t) {this.imgs.delete.push(image.id_t);}
        this.imgs.display.splice(index, 1);
        this.imgs.dbase.splice(index, 1);
        
      } else {
        let cnt = this.imgs.display.filter((v) => v.id != 0).length;
        this.imgs.display.splice(index - cnt, 1);
      }
      //this.imgs.display.splice(index, 1);
    },
  },
  async mounted() {
    this.getIssueType();
    this.getUser();
  },
  watch: {
    images(newVal, oldVal) {
      if (newVal) {
        this.imgs = newVal;
        this.displayImgs = newVal;
      }
    },
  },
};
</script>
